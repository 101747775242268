.buttonApplyCoupon{

    margin-bottom: 30px !important;
    margin-right: 16px !important;

}
.buttonCreateCoupon {
    background: #84665b !important;
    color: #ffffff !important;
    height: 40px !important;
  }
  
  .buttonCreateCoupon:disabled {
    background: #d8d8d8 !important; /* Light grey */
    color: #aaaaaa !important; /* Dark grey */
  }
  