.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.swal-button--confirm {
  background-color: #84665b !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.colorButtonLogin {
  background-color: #84665b !important;
}

.colorButtonLogin:hover {
  background-color: #997568 !important;
}

.MuiOutlinedInput-root:hover fieldset {
  cursor: help;
  border: #84665b solid 2px !important;
  outline: none !important;
  transition: border 30ms ease-in-out;
}

.MuiOutlinedInput-root:focus fieldset {
  border: #84665b solid 5px !important;
  outline: none !important;
  transition: border 30ms ease-in-out;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #84665b; /* Maintain your border color */
  box-shadow: 0 0 0 0.2rem rgba(124, 87, 74, 0.37);
}

#signInButton:hover,
#forgotPasswordButton:hover,
#signUpButton:hover,
#backHomeButton:hover,
#pageNotFoundButton:hover {
  background-color: #b08968 !important;
}

.autoSelectSize {
  height: 10.8px !important;
}

.goBackButton,
.goBackButton-admin {
  /* position: absolute; */
  /* z-index: 9999; */
  display: flex;
}

.goBackButton-supplier {
  position: absolute;
  z-index: 9999;
}

.dots-products-lg {
  width: 8px !important;
  height: 8px !important;
  background: #22bb33 !important;
  border-radius: 50%;
  margin-bottom: 10px !important;
  margin-left: 4px !important;
}

.dots-products-sm {
  width: 5px !important;
  height: 5px !important;
  background: #22bb33 !important;
  border-radius: 50%;
  margin-bottom: 10px !important;
  margin-left: 4px !important;
}

.loadingButtonStyleApply {
  margin-right: 15px;
}

.loadingButtonStyleNotApply {
  margin-right: 0px;
}


.swal-content img {
  display: block;
  margin: 0 auto 10px;
}

.swal-content {
  text-align: center;
}
